import useSWR from "swr";
import { Skeleton } from "antd";
import { NCategory } from "@interfaces";
import { useAppDispatch } from "@redux/store";
import { StoreCategoryService } from "@services";
import { OptionSlider } from "../../modules/option-slider";
import { NOption } from "@interfaces/components/option";
import { selectCategory } from "@redux/slices";
import { getPublicMediaUrl } from "../../../utils";
import { useEffect } from "react";
import { getSearchParams } from "../../../hooks/getSearchParams";
import { useParams, useRouter } from "next/navigation";

export const StoreCategories = () => {
    const dispatch = useAppDispatch();
    const category = getSearchParams()?.category;
    const router = useRouter();
    const params = useParams();
    const service = StoreCategoryService.getInstance();
    const handleChange = (item: NOption.Option | null) => {
        dispatch(selectCategory(item));
        router.push(`/${params?.lng}/search?category=${item?.slug ?? ""}`);
    };

    const { data, isLoading } = useSWR(["categories"], () => {
        return service.getAll();
    });

    const categories = data?.map((item) => {
        return {
            id: item.id,
            name: item.name,
            photo: getPublicMediaUrl(item.photo ?? ""),
            visibility: item.visibility,
            slug: item.slug,
        } as NCategory.Category;
    });

    useEffect(() => {
        if (categories?.length && category) {
            const selectedOption = categories.find(
                (item) => item.slug === category,
            );
            if (selectedOption) {
                dispatch(selectCategory(selectedOption));
            }
        }
    }, [categories?.length]);

    return (
        <>
            {isLoading && <Skeleton active />}
            {!isLoading && (
                <OptionSlider
                    optionList={categories ?? []}
                    onChangeOption={handleChange}
                    currentCategory={""}
                />
            )}
        </>
    );
};
