import BaseApi from "./baseApi.service";
import { ISearchStoreRequest, IStore } from "@interfaces";
import { PaginationResponseDto } from "../../data-access/response/pagination-response.dto";

export class StoreService extends BaseApi {
    private static instance: StoreService;

    constructor() {
        super("market");
    }

    public static getInstance(): StoreService {
        if (!StoreService.instance) {
            StoreService.instance = new StoreService();
        }
        return StoreService.instance;
    }

    public static resetInstance(): void {
        this.instance = new StoreService();
    }

    private readonly END_POINT = {
        businessType: "v1/store",
    };

    public async search(
        dto: ISearchStoreRequest,
    ): Promise<PaginationResponseDto<IStore>> {
        const data = await this.post<PaginationResponseDto<IStore>>(
            `${this.END_POINT.businessType}/search`,
            dto,
        );
        if (data?.data) {
            return data?.data;
        }
        return new PaginationResponseDto<IStore>();
    }
}
