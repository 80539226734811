import { Distance, DistanceUnitType } from "@interfaces";

export const getDistanceByUnit = (distance: Distance): string | null => {
    let value: string | null = null;
    let unit = "";
    if (!distance?.value) {
        return value;
    }
    if (distance.unit === DistanceUnitType.kilometer) {
        value = distance.value.toFixed(2);
        unit = "km";
    } else if (distance.unit === DistanceUnitType.meter) {
        value = (distance.value / 1000)?.toFixed(2);
        unit = "m";
    }
    return `${value} ${unit}`;
};
