import { cn } from "@utils/tailwind-marge";
import { Distance } from "@interfaces";
import { getMovingTime } from "@utils/getMovingTime";
import { getDistanceByUnit } from "@utils/getDistanceByUnit";

type Props = React.HTMLAttributes<HTMLDivElement> & {
    distance: Distance;
};
export const StoreDistance: React.FC<Props> = ({
    className,
    distance,
    ...rest
}) => (
    <div
        {...rest}
        className={cn(
            className,
            "flex flex-nowrap items-center gap-2 ",
            "text-neutral-40 text-ss font-normal leading-4",
        )}
    >
        {getMovingTime(distance)}
        <div className="min-w-1 min-h-1 w-1 h-1 bg-neutral-40 rounded-full" />
        {getDistanceByUnit(distance)}
    </div>
);
