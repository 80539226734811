import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Image } from "antd";
import { cn } from "@utils/tailwind-marge";
import { NBusinessType } from "../../../interfaces/components/businessType";
import { useAppSelector } from "../../../redux/store";
import { marketSelector } from "../../../redux/slices";
import { getPublicMediaUrl, logSearch } from "../../../utils";
import { FALLBACK_IMAGE_PATH } from "../../../common/constant";
import { SearchLogService } from "@services/api/search-log.service";

const searchLogService = SearchLogService.getInstance();

export const BusinessTypeSlider: React.FC<NBusinessType.IProps> = ({
    businessTypes,
    onChangeBusinessType,
}) => {
    const market = useAppSelector(marketSelector);
    const [currentBusinessType, setCurrentBusinessType] = useState<string>();
    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        variableWidth: true,
        speed: 500,
        slidesToScroll: 2,
    };

    const handleChange = (businessType: NBusinessType.BusinessType) => {
        if (market.selectedBusiness?.code === businessType.code) {
            onChangeBusinessType(null);
            setCurrentBusinessType("");
        } else {
            onChangeBusinessType(businessType);
            setCurrentBusinessType(businessType.code);
        }
        logSearch(businessType.name);
        searchLogService.createSearchLog(businessType.name);
    };

    useEffect(() => {
        if (market?.selectedBusiness?.code) {
            setCurrentBusinessType(market?.selectedBusiness?.code);
        }
    }, [market?.selectedBusiness?.code]);

    return (
        <Slider {...settings}>
            {businessTypes.map((businessType: NBusinessType.BusinessType) => (
                <div
                    key={businessType.code}
                    className={cn(
                        "font-normal px-4 py-2 text-center min-w-24 cursor-pointer rounded-md transition",
                        currentBusinessType === businessType.code &&
                            "bg-primary-5",
                    )}
                    onClick={() => handleChange(businessType)}
                >
                    <div>
                        <Image
                            className="object-contain"
                            alt=""
                            src={getPublicMediaUrl(businessType?.photo ?? "")}
                            fallback={getPublicMediaUrl(FALLBACK_IMAGE_PATH)}
                            width={52}
                            height={52}
                            preview={false}
                        />
                    </div>
                    {businessType.name}
                </div>
            ))}
        </Slider>
    );
};
