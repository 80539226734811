export interface IStore {
    id: string;
    name: string;
    location: Location;
    host: string;
    businesses: StoreBusiness[];
    categories: StoreCategory[];
    settings: StoreSetting[];
    rating: number;
    distance?: Distance;
    subDomain: string;
}

export class Location {
    city?: LocationData;
    district?: LocationData;
    ward?: LocationData;
    postalCode?: string;
    orderingAddress?: string;
    lat?: number;
    lng?: number;
}
export class LocationData {
    value: number | string;
    name: string;
}

export class StoreBusiness {
    code: string;
    name: string;
}

export class StoreCategory {
    code: string;
    name: string;
}

export enum StoreSettingType {
    banner = "banner",
    avatar = "avatar",
}

export class StoreSetting {
    type: StoreSettingType;
    value: string;
}

export enum DistanceUnitType {
    meter = "meter",
    kilometer = "kilometer",
}

export class Distance {
    value: number;
    unit: DistanceUnitType;
}
