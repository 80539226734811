import useSWR from "swr";
import { BusinessTypeService } from "@services/api/business-type.service";
import { Skeleton } from "antd";
import { useAppDispatch } from "@redux/store";
import { selectBusiness } from "@redux/slices";
import { ItemVisibilityEnum } from "../../../enums/items-visibility.enum";
import { BusinessTypeSlider } from "../../modules/business-type-slider";
import { NBusinessType } from "../../../interfaces/components/businessType";
import { useEffect } from "react";
import { getSearchParams } from "../../../hooks/getSearchParams";
import { useParams, useRouter } from "next/navigation";

export const BusinessTypes = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const params = useParams();
    const service = BusinessTypeService.getInstance();
    const businessType = getSearchParams()?.businessType;
    const handleChange = (item: NBusinessType.BusinessType | null) => {
        dispatch(selectBusiness(item));
        router.push(
            `/${params?.lng}/search?business-type=${
                item?.code ?? item?.slug ?? ""
            }`,
        );
    };

    const { data, isLoading } = useSWR(
        ["business-types"],
        () => {
            return service.getAll();
        },
        { revalidateOnFocus: false },
    );

    const businessTypes = data?.map((item) => {
        return {
            id: item.id,
            name: item.name,
            photo: item.imageUrl ?? item.imageFallbackUrl,
            visibility: ItemVisibilityEnum.VISIBLE,
            code: item.code,
        } as NBusinessType.BusinessType;
    });

    useEffect(() => {
        if (businessTypes?.length && businessType) {
            const selectedOption = businessTypes.find(
                (item) => item.code === businessType,
            );
            if (selectedOption) {
                dispatch(selectBusiness(selectedOption));
            }
        }
    }, [businessTypes?.length]);

    return (
        <>
            {isLoading && <Skeleton active />}
            {!isLoading && (
                <BusinessTypeSlider
                    businessTypes={businessTypes ?? []}
                    onChangeBusinessType={handleChange}
                />
            )}
        </>
    );
};
