import { IS_SERVER } from "@utils/is-server";
import { useSearchParams } from "next/navigation";
import { useState } from "react";

export const FILTER_KEY = "filter";
export const CATEGORY_KEY = "category";
export const BUSINESS_TYPE_KEY = "business-type";

type SearchParams = {
    filter?: string;
    category?: string;
    businessType?: string;
};

export const getSearchParams = (): SearchParams => {
    const searchParams = useSearchParams();
    return {
        filter: searchParams.get(FILTER_KEY) ?? "",
        category: searchParams.get(CATEGORY_KEY) ?? "",
        businessType: searchParams.get(BUSINESS_TYPE_KEY) ?? "",
    };
};
