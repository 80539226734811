import { useEffect, useState } from "react";
import Slider from "react-slick";
import { NOption } from "../../../interfaces/components/option";
import { cn } from "@utils/tailwind-marge";
import { useAppSelector } from "../../../redux/store";
import { marketSelector } from "../../../redux/slices";
import { logSearch } from "@utils/logSearch";
import { useParams, useRouter } from "next/navigation";

export const OptionSlider: React.FC<NOption.IProps> = ({
    onChangeOption,
    optionList,
}) => {
    const market = useAppSelector(marketSelector);

    const router = useRouter();
    const params = useParams();

    const [currentCategory, setCurrentCategory] = useState<string>(
        market?.selectedCategory?.slug ?? "",
    );
    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        variableWidth: true,
        speed: 500,
        slidesToScroll: 2,
    };

    const handleChange = (option: NOption.Option) => {
        if (market?.selectedCategory?.slug === option.slug) {
            setCurrentCategory("");
            onChangeOption(null);
        } else {
            onChangeOption(option);
            setCurrentCategory(option?.slug ?? "");
            router.push(`/${params?.lng}/search?category=${option.slug ?? ""}`);
        }
        logSearch(option.name);
    };

    useEffect(() => {
        if (market?.selectedCategory?.slug) {
            setCurrentCategory(market?.selectedCategory?.slug);
        }
    }, [market?.selectedCategory?.slug]);

    return (
        <section>
            <Slider {...settings}>
                {optionList.map((item) => (
                    <div key={item.slug}>
                        <div
                            className={cn(
                                "bg-primary-5 text-primary font-medium text-sm px-4 py-1.5 rounded-3xl mr-3 leading-6 transition",
                                currentCategory === item.slug &&
                                    "bg-primary text-white",
                                "caption-medium",
                            )}
                            onClick={() => handleChange(item)}
                        >
                            {item.name}
                        </div>
                    </div>
                ))}
            </Slider>
        </section>
    );
};
