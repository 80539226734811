import { Distance, DistanceUnitType } from "@interfaces";
import dayjs from "dayjs";
import { AVERRAGE_SPEED } from "../common/constant";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const getMovingTime = (distance: Distance): string | null => {
    const { t } = useTranslation("common");
    const speed = AVERRAGE_SPEED;
    let time: number | null = null;
    const hourInMinute = 60;
    if (!distance?.value) {
        return time;
    }
    if (distance.unit === DistanceUnitType.kilometer) {
        time = (distance.value * hourInMinute) / speed;
    }
    dayjs.locale(t("common.lang_code"));
    return !time ? "" : dayjs.duration(time ?? 0, "minutes").humanize(true); // in a minute;
};
