import BaseApi from "./baseApi.service";
import { IBusinessType } from "@interfaces";

export class BusinessTypeService extends BaseApi {
    private static instance: BusinessTypeService;

    constructor() {
        super("market");
    }

    public static getInstance(): BusinessTypeService {
        if (!BusinessTypeService.instance) {
            BusinessTypeService.instance = new BusinessTypeService();
        }
        return BusinessTypeService.instance;
    }

    public static resetInstance(): void {
        this.instance = new BusinessTypeService();
    }

    private readonly END_POINT = {
        businessType: "v1/business-type",
    };

    public async getAll(): Promise<IBusinessType[]> {
        const data = await this.get<IBusinessType[]>(
            this.END_POINT.businessType,
        );
        if (data?.data) {
            return data?.data;
        }
        return [];
    }
}
