import { cn } from "@utils/tailwind-marge";

type Props = React.HTMLAttributes<HTMLDivElement>;
export const ShopName: React.FC<Props> = ({ className, children, ...rest }) => (
    <div
        {...rest}
        className={cn(className, "flex flex-nowrap items-center gap-2")}
    >
        <img src="/icons/confirmed-icon.svg" />
        <p className="text-neutral-60 text-sm font-bold leading-4 text-nowrap truncate">
            {children}
        </p>
    </div>
);
