import { cn } from "@utils/tailwind-marge";
import { Button } from "antd";
import { useParams, useRouter } from "next/navigation";
import { ReactElement } from "react";

type Props = React.HTMLAttributes<HTMLDivElement>;

export const Actions = ({ className, ...rest }: Props): ReactElement => {
    const router = useRouter();
    const params = useParams();

    const viewMyOrder = () => {
        router.push(`/my-order`);
    };

    return (
        <div {...rest} className={cn("flex gap-4", className)}>
            {/* <Action src="/icons/favourite.svg" /> */}
            <Action src="/icons/receipt.svg" onClick={viewMyOrder} />
        </div>
    );
};

type ActionProps = Props & { src?: string };
const Action = ({ src, className, ...rest }: ActionProps) => {
    return (
        <Button
            {...rest}
            className={cn(
                "w-10 h-10 p-[6px] outline-none border-none shadow-none",
                className,
            )}
        >
            <img src={src} className="w-full h-full" />
        </Button>
    );
};
