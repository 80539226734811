import useSWR from "swr";
import { Skeleton } from "antd";
import { NShop, StoreSettingType } from "@interfaces";
import { StoreService } from "../../../services/api/store.service";
import { NearbyShops } from "../../modules/shop-list/shop-nearby";
import { getPublicMediaUrl } from "../../../utils";
import { useAppSelector } from "../../../redux/store";
import { guestSelector, marketSelector } from "../../../redux/slices";
import { useEffect } from "react";
import { STORE_FALLBACK_IMAGE_PATH } from "../../../common/constant";

type Prop = {
    onViewDetail: (store: NShop.Item) => void;
    businessType?: string | null;
    category?: string | null;
    filter: string;
    title?: string;
};

export const NearlyStores = (props: Prop) => {
    const { lat, lng } = useAppSelector(guestSelector);
    const latLng =
        lat && lng
            ? {
                  lat,
                  lng,
              }
            : null;

    const { onViewDetail, filter, title } = props;

    const service = StoreService.getInstance();

    const market = useAppSelector(marketSelector);

    useEffect(() => {
        if (
            market?.selectedCategory ||
            market?.selectedBusiness ||
            filter ||
            latLng
        ) {
            mutate;
        }
    }, [market?.selectedCategory, market?.selectedBusiness, filter, latLng]);

    const params = {
        pageIndex: 1,
        pageSize: 10,
        businessType: market?.selectedBusiness?.code,
        category: market?.selectedCategory?.id,
        latlng: latLng,
        filter: filter,
    };

    const { data, isLoading, mutate } = useSWR(["nearly-stores", params], () =>
        service.search(params),
    );

    const stores = data?.data?.map((item) => {
        const image =
            item.settings?.find(
                (setting) => setting.type === StoreSettingType.avatar,
            )?.value ?? "";
        const address = item.location?.orderingAddress ?? "";
        return {
            id: item.id,
            name: item.name,

            image:
                getPublicMediaUrl(image) ??
                getPublicMediaUrl(STORE_FALLBACK_IMAGE_PATH),
            address: address,
            distance: item.distance,
            rating: item.rating,
            subDomain: item.subDomain,
        } as NShop.Item;
    });

    return (
        <>
            {isLoading && <Skeleton active />}
            {!isLoading && (
                <NearbyShops
                    title={title}
                    stores={stores ?? []}
                    onViewDetail={onViewDetail}
                />
            )}
        </>
    );
};
