const LocationSelector = dynamic(() => import("./location-selector"), {
    ssr: false,
});
import dynamic from "next/dynamic";
import { Actions } from "./actions";
import { cn } from "@utils/tailwind-marge";

export const MarketHeader = () => {
    return (
        <>
            <div
                className={cn(
                    "divider",
                    "flex flex-wrap justify-between flex-row-reverse",
                    "bg-white w-full py-[10px] px-4",
                )}
            >
                {/* TODO: TEMPORARY Disable for now */}
                {/* <LocationSelector />
                <Actions /> */}
                <Actions />
            </div>
        </>
    );
};
