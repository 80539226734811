import BaseApi from "./baseApi.service";
import { NSearchLog } from "../../interfaces/components/search-log";

export class SearchLogService extends BaseApi {
    private static instance: SearchLogService;

    constructor() {
        super("market");
    }

    public static getInstance(): SearchLogService {
        if (!SearchLogService.instance) {
            SearchLogService.instance = new SearchLogService();
        }
        return SearchLogService.instance;
    }

    public static resetInstance(): void {
        this.instance = new SearchLogService();
    }

    private readonly END_POINT = {
        searchLog: "v1/search-log",
    };

    public async createSearchLog(filter: string): Promise<boolean> {
        const data = await this.post<any>(`${this.END_POINT.searchLog}`, {
            value: filter,
        });
        if (data?.data) {
            return data?.data;
        }
        return false;
    }

    public async getAll(): Promise<NSearchLog.ISearchLog[]> {
        const data = await this.get<NSearchLog.ISearchLog[]>(
            this.END_POINT.searchLog,
            {},
        );
        if (data?.data) {
            return data?.data;
        }
        return [];
    }
}
