import { cn } from "@utils/tailwind-marge";

type Props = React.HTMLAttributes<HTMLDivElement>;
export const ShopInfoContainer: React.FC<Props> = ({
    className,
    children,
    ...rest
}) => (
    <div {...rest} className={cn(className, "overflow-auto")}>
        <div className="flex flex-col gap-2">{children}</div>
    </div>
);
