import { StoreDistance } from "../components/distance";
import { Promotion } from "../components/promotion";
import { Rating } from "../components/rating";
import { ShopInfoContainer } from "../components/shop-info-container";
import { ShopName } from "../components/shop-name";
import { Tag } from "../components/tag";
import { useTranslation } from "react-i18next";
import s from "./shop-near.module.scss";
import { NShop } from "@interfaces";
import { Image } from "antd";
import { getPublicMediaUrl } from "../../../../utils";
import { STORE_FALLBACK_IMAGE_PATH } from "../../../../common/constant";
import { cn } from "@utils/tailwind-marge";

type NearByShopProps = {
    title?: string;
    stores: NShop.Item[];
    onViewDetail: (store: NShop.Item) => void;
};

export const NearbyShops: React.FC<NearByShopProps> = ({
    stores,
    onViewDetail,
    title,
}) => {
    const { t } = useTranslation("common");

    return (
        <section className={`${s.nearbyShopContainer}`}>
            <h1 className={`${s.title} !text-primary mb-6`}>{title}</h1>
            {!stores?.length && (
                <h4 className="text-center text-base text-neutral-30 font-sans font-semibold mb-4">
                    {t("common.no_near_store")}
                </h4>
            )}

            {stores?.map((store) => (
                <div
                    onClick={() => onViewDetail(store as NShop.Item)}
                    className={`${s.shopContainer} mb-6`}
                >
                    <div className={`flex gap-2`}>
                        <div className={`${s.imageContainer}`}>
                            <div className="cursor-pointer overflow-hidden rounded-lg">
                                <Image
                                    src={store.image}
                                    fallback={getPublicMediaUrl(
                                        STORE_FALLBACK_IMAGE_PATH,
                                    )}
                                    alt="gift-card"
                                    className={cn(
                                        `${s.image} object-cover z-0`,
                                        "hover:scale-125 transition",
                                    )}
                                    preview={false}
                                />

                                {/* <Tag
                                    type="green"
                                    className="rounded-tl-lg rounded-br-lg absolute top-0"
                                >
                                    Khuyến mãi
                                </Tag> */}
                            </div>
                        </div>

                        <ShopInfoContainer>
                            <ShopName>{store.name}</ShopName>
                            {store.distance?.value && (
                                <StoreDistance distance={store.distance} />
                            )}
                            {/* <Rating
                                rating={{
                                    commentTotal: 0,
                                    rate: store.rating,
                                }}
                            /> */}
                            {/* <Promotion
                                promotion={{
                                    title: "Ưu đãi 10%",
                                    description:
                                        "Bạn có thêm 2 vouncher khuyến mãi",
                                }}
                            /> */}
                        </ShopInfoContainer>
                    </div>
                </div>
            ))}
        </section>
    );
};
